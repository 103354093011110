.container {
	margin: 0 auto;
	width: 50em;
	max-width: 90%;
}

.container--flush {
	margin: 0 auto;
	width: 60em;
	max-width: 100%;
}

body {
	font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
	font-size: 16px;
	line-height: 1.42857143;
	color: #333;
}

a {
	text-decoration: none;
	color: #0088cc;
}

a:hover {
	text-decoration: underline;
	color: #005580;
}

h1, h2, h3, h4, h5 {
	font-weight: 400;
	font-family: "Bodoni MT", Didot, "Didot LT STD", "Hoefler Text", Garamond, "Times New Roman", serif;
}

h1 {
	font-weight: 200;
}

h1 small, h2 small, h3 small {
	font-size: 70%;
	color: #666;
}

.fill-img {
	width: 100%;
}

hr {
	margin: 2em 0;
	border: 0;
	border-top: 1px solid #eeeeee;
	border-bottom: 1px solid #ffffff;
}

hr.half { 
	margin: 1em 0;
}

hr.space { 
	border-color: transparent;
}

.flush-bottom {
	margin-bottom: 0;
}

.brand-name {
	color: #000;
	background-color: #fffc00;
	text-transform: lowercase;
	margin-right: 1em;
}

.pure-menu-horizontal {
	white-space: normal;
}

.primary-menu {
	padding: 0.5% 0;
	background-color: #fff;
	background-color: rgba(255,255,255,0.95);
	font-weight: normal;
	text-transform: lowercase;
	transition: background-color 0.5s;
}

.primary-menu--anchored {
	background-color: rgba(255,255,255,0.65);
}

.pure-menu-fixed {
	position: static;
}
/* md breakpoint */
@media screen and (min-width: 48em) {
	.pure-menu-fixed {
		position: fixed;
	}
}

.clearfix { overflow: hidden; }
.pull-left { float:left; }
.pull-right { float:right; }


.page-title {
	margin-top: 5em;
}

.grid-gutter {
	margin-right: 3em;
}


.photo-tag {
	position: relative;
	z-index: 1;
}

.photo-tag__trigger {
	font-weight: bold;
}

.photo-tag__trigger--active {
	background: #0088cc;
	color: #fff;
}

.photo-tag__tag {
	position: absolute;
	border: 0.5em solid #eee;
	border-color: rgba(255,255,255,0.25);
	opacity: 0.05;
	transition: opacity 0.5s;
}

.photo-tag__tag--active {
	opacity: 1;
}

.photo-tag__tag--me {
	top: 10%;
	left: 59%;
	right: 26%;
	bottom: 52%;
}

.photo-tag__tag--scott {
	top: 19%;
	left: 31%;
	right: 54%;
	bottom: 45%;
}


.post-it {
	position: relative;
	z-index: 2;
	background: #fffc00;
	padding-top: 0.5em;
	padding-bottom: 0.5em;
	box-shadow: 1px 1px 3px rgba(0,0,0,0.25);
}

.post-it::after {
	content: " ";
	position: absolute;
	display: block;
	top: -5px;
	left: 50%;
	margin-left: -5px;
	border-color: transparent;
	border-style: solid;
	border-width: 0 5px 5px;
	border-bottom-color: #fffc00;
}